/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import MenuFooter from './MenuFooter.js'
import Newsletter from './Newsletter.js'
import Logo from './Logo.js'
import Social from './SocialIcons.js'
import { useStaticQuery, graphql, Link } from 'gatsby'

const FooterContainer = styled.footer`
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  /*
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  */
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  > div {
    align-items: stretch;
    justify-content: flex-start;

    ul li {
      padding: 0 !important;
    }
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    border-left: none !important;
    border-right: none !important;
  }

  .teamviewer a {
    border-color: ${props => props.assetColor || '#000'} !important;
    &:hover {
      background-color: ${props => props.assetColor || '#ddd'} !important;
    }
  }
`
const FooterRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 2px solid #000;
  font-size: ${props => props.theme.fontSizes[6]}px;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[4]}px !important;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex-flow: row wrap;
  }

  > div {
    padding: 30px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      border-right: none;
      padding: 30px 15px;
    }
  }
`

const grid1 = css``
const grid3 = css``
const grid2 = css`
  border-right: 2px solid #000;
`

/*
const grid1 = css`
  max-width: 100%;
`
const grid2 = css`
  max-width: 100%;
  border-right: 2px solid #000;
`
const grid3 = css`
  max-width: 100%;
`
*/

const gridFlex = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const FooterColumn1 = styled.div`
  border-right: 2px solid #000;
  flex: 0 0 350px;
  max-width: 350px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    flex: 0 0 270px;
    max-width: 270px;
  }
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    display: none !important;
  }
`

const FooterColumn2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: auto;

  ul {
    text-align: right;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      text-align: left;
      margin-top: 20px;
    }
  }
  li {
    margin-bottom: 20px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      margin-bottom: 15px;
    }
    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      margin-bottom: 10px;
    }
  }
  a {
    display: inline-block;
    min-width: 170px;
    max-width: 370px;
    width: 100%;
    text-align: left;
    border: 2px solid ${props => props.theme.colors.red};
    border-radius: 999px;
    padding: 5px 30px;
    line-height: 1.5;

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      padding: 4px 20px;
      width: auto;
    }
    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      padding: 2px 18px;
      width: auto;
    }

    opacity: 1 !important;
    &:hover {
      background-color: ${props => props.theme.colors.red};
    }
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[4]}px;
    order: 2;
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    border-top: 2px solid #000;
    font-size: ${props => props.theme.fontSizes[3]}px;
    flex: 1 0 100%;
  }
`

const FooterColumn3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 550px;
  max-width: 550px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    flex: 0 0 490px;
    max-width: 490px;
    padding-right: 60px !important;
    font-size: ${props => props.theme.fontSizes[4]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    order: 1;
    padding-right: 15px !important;
    border-right: 2px solid #000 !important;
    flex: 0 0 50%;
    max-width: 50%;
    font-size: ${props => props.theme.fontSizes[4]}px;
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    border-right: none !important;
    flex: 1 1 680px;
    max-width: 680px;
    font-size: ${props => props.theme.fontSizes[3]}px;
  }
`

const SubFooterRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex-flow: row wrap;
  }
  > div {
    padding: 30px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      border-right: none;
      padding: 30px 15px;
    }
  }
`

const SubFooterColumn1 = styled.div`
  border-right: 2px solid #000;
  flex: 0 0 350px;
  max-width: 350px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    display: none !important;
    padding: 0 !important;
  }
`

const SubFooterColumn2 = styled.div`
  white-space: pre;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes[1]}px;
  max-width: 100%;
  flex: auto;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    flex: 0 1 490px;
    max-width: 490px;
    order: 3;
    padding-right: 60px !important;
    border-right: none;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex-flow: row wrap;
  }
`

const SubFooterColumn3 = styled.div`
  border-right: 2px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-size: 15px;
  flex: 0 0 275px;
  max-width: 275px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    flex: 0 0 270px;
    max-width: 270px;
    order: 1;
  }
`
const SubFooterColumn4 = styled.div`
  flex: 0 0 275px;
  max-width: 275px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    flex: 1 1 auto;
    order: 2;
    border-right: 2px solid #000;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    justify-content: flex-start !important;
    padding: 0 15px !important;
  }
`

const FooterLogoContainer = styled.div`
  max-width: 220px;
`

const Footer = props => {
  const contactQuery = useStaticQuery(graphql`
    {
      contentfulCompany(unternehmensnamekurz: { eq: "EXTENDIT" }) {
        telefonnummer
        unternehmensname
        adresse {
          internal {
            content
          }
        }
        email
      }
    }
  `)
  return (
    <FooterContainer assetColor={props.passColor}>
      <FooterRow>
        <FooterColumn1 css={[gridFlex, grid1]}>
          <MenuFooter />
        </FooterColumn1>
        <FooterColumn2 css={grid2} className="teamviewer">
          <div>TeamViewer</div>
          <ul
            css={css`
              text-align: right;
            `}
          >
            <li>
              <a
                href="https://download.teamviewer.com/download/TeamViewerQS.exe"
                target="_blank"
                rel="noreferrer"
              >
                Windows
              </a>
            </li>
            <li>
              <a
                href="https://download.teamviewer.com/download/TeamViewerQS.dmg"
                target="_blank"
                rel="noreferrer"
              >
                Apple
              </a>
            </li>
          </ul>
        </FooterColumn2>
        <FooterColumn3 css={grid3}>
          <div>
            Juhu! Noch ein Newsletter!
            <br />
            Aber ein wirklich extrem unterhaltsamer und guter!
          </div>
          <Newsletter />
        </FooterColumn3>
      </FooterRow>
      <SubFooterRow>
        <SubFooterColumn1 css={[grid1, gridFlex]}>
          <FooterLogoContainer>
            <Logo
              passColor={props.passColor}
              radiusLang={'0px'}
              radiusKurz={'999px'}
            />
          </FooterLogoContainer>
        </SubFooterColumn1>
        <SubFooterColumn2 css={grid2}>
          <div>
            {contactQuery.contentfulCompany.unternehmensname}
            <br />
            {contactQuery.contentfulCompany.adresse.internal.content}
          </div>
          <div>
            <a href={`tel:${contactQuery.contentfulCompany.telefonnummer}`} onClick={() => typeof window !== "undefined" && window.gtag("event", "clickTelefon", {send_to: "AW-983019446/8KoeCJfTiskCELbf3tQD"})}>
              {contactQuery.contentfulCompany.telefonnummer}
            </a>
            <br />
            <a href={`mailto:${contactQuery.contentfulCompany.email}`} onClick={() => typeof window !== "undefined" && window.gtag("event", "clickEmail", {send_to: "AW-983019446/eTYmCInXiskCELbf3tQD"})}>
              {contactQuery.contentfulCompany.email}
            </a>
          </div>
        </SubFooterColumn2>
        <SubFooterColumn3>
          <Link to="/impressum/">Impressum</Link>
          <Link to="/datenschutz/">Datenschutz</Link>
          <Link to="/agb/">AGB</Link>
          <Link to="/partner/">Partner</Link>
        </SubFooterColumn3>
        <SubFooterColumn4 css={gridFlex}>
          <Social />
        </SubFooterColumn4>
      </SubFooterRow>
    </FooterContainer>
  )
}

export default Footer
